import {
    /* Firebase */
    /* Context */
    /* Components */
    /* Context */
    /* Views */
    /* Custom Hooks */
    logComponentInit,
    /* Service Classes */
    /* Utility Functions */
    /* Assets */
    /* Icons */
    /* Configs */
    /* Types */
    /* Interfaces */
    /* DeveloperTools */
    DebugService,
    debug,
    logInit,
    trace,
    m,
} from '../../app-index'

/* Trace vars */
const run = 0
const file = 'HeaderView'
const msg = (copy, fileName = file) => m(copy, fileName)
/* END Trace vars */

export default function HeaderView({ display, additionalClasses, ...props }) {
    logInit && logComponentInit(file)

    const propStyles = {
        display: `${display === 'none' ? 'none' : 'flex'}`,
    }

    return (
        <div
            className={`header-view ${additionalClasses}`}
            style={propStyles}
        >
            {props.children}
        </div>
    )
}