import {
    /* Firebase */
    /* Context */
    MainProvider,
    initialMainState,
    /* Components */
    AppView,
    /* Views */
    /* Custom Hooks */
    logComponentInit,
    /* Service Classes */
    /* Utility Functions */
    /* Assets */
    /* Icons */
    /* Configs */
    /* Types */
    /* Interfaces */
    /* DeveloperTools */
    DebugService,
    debug,
    logInit,
    m
} from '../../app-index'

// import Favicon from 'react-favicon'
// import hexFavicon from '../../_assets/favicon.ico'

/* Trace vars */
const run = 0
const file = 'App'
const msg = (copy, fileName = file) => m(copy, fileName)
/* END Trace vars */

export default function App() {
    logInit && logComponentInit(file)
    debug && DebugService.validateInitialState('MainContext', initialMainState) && log('Initial State Validated')

    return (
        <MainProvider>
            {/* <Favicon url='https://github.com/ashthomasweb/portfolio-v2/blob/main/public/favicon.ico' /> */}
            <AppView />
        </MainProvider>
    )
}

