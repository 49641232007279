import { useContext, useState, useRef } from 'react'
import {
    /* Firebase */
    /* Components */
    SVGIcon,
    /* Context */
    MainContext,
    /* Views */
    /* Custom Hooks */
    /* Service Classes */
    /* Utility Functions */
    /* Assets */
    /* Icons */
    /* Configs */
    /* Types */
    /* Interfaces */
    /* DeveloperTools */
    debug,
    logInit,
    trace,
    m
} from '../../app-index'

/* Trace vars */
const run = 0
const file = 'Contact'
const msg = (copy, fileName = file) => m(copy, fileName)
/* END Trace vars */

export default function Contact() {
    const {
        mainState: {
            contactActive,
            sidebarActive,

        }
    } = useContext(MainContext)

    const [displayEmailAlert, setDisplayEmailAlert] = useState(false)
    const hiddenTextRef = useRef(null)



    const handleEmailCopy = () => {
        const textarea = hiddenTextRef.current
        textarea.focus()
        textarea.select()

        try {
            document.execCommand('copy')
            // console.log(successful ? 'Text copied!' : 'Failed to copy text.')
        } catch (err) {
            console.error('Failed to copy text: ', err)
        }

        setDisplayEmailAlert(true)
        setTimeout(() => {
            setDisplayEmailAlert(false)
        }, 5000)
    }

    return (
        <section className={`contact-container contact-fade ${contactActive ? 'isActive' : ''}`}>
            <div
                className={`email-container copy-email ${displayEmailAlert ? 'email-alert-active' : ''}`}
                onClick={handleEmailCopy}
            >
                <SVGIcon
                    src='copy'
                    scale={0.8}
                    additionalClass={`copy-icon `}
                />
                <span className={`copied-alert ${displayEmailAlert ? 'isDisplayed' : ''}`}>
                    Email Copied!
                </span>
               
                <span className={`email ${!displayEmailAlert ? 'isDisplayed' : ''}`} >
                    ashthomascode@gmail.com
                </span>

                {/* HIDDEN - ONLY CONTAINS COPY VALUE */}
                <textarea
                    ref={hiddenTextRef}
                    style={{ position: 'fixed', opacity: 0, pointerEvents: 'none' }}
                    value='ashthomascode@gmail.com'
                    readOnly
                ></textarea>
                {/* HIDDEN - ONLY CONTAINS COPY VALUE */}

            </div>
            <div className='github-container'>
                <a
                    href='https://github.com/ashthomasweb'
                    target='_blank'
                >
                    <span className='github-profile'>
                        <SVGIcon src='githubSquare' />
                        github.com/ashthomasweb
                    </span>
                </ a>
            </div>
        </section>
    )
}