import { useContext, useState } from 'react'
import {
    /* Firebase */
    /* Components */
    /* Context */
    MainContext,
    /* Views */
    /* Custom Hooks */
    /* Service Classes */
    DisplayService,
    /* Utility Functions */
    /* Assets */
    /* Icons */
    /* Configs */
    /* Types */
    /* Interfaces */
    /* DeveloperTools */
    debug,
    logInit,
    trace,
    m
} from '../../app-index'

/* Trace vars */
const run = 0
const file = 'NeuButton'
const msg = (copy, fileName = file) => m(copy, fileName)
/* END Trace vars */

export default function NeuButton({ copy, nav, click, isActive, additionalClass, ...props }) {
    const {
        mainState: {
        }
    } = useContext(MainContext)

    const [isPressed, setIsPressed] = useState(false)

    const handleButtonPress = () => {
        // trace(run) && log(...msg('handleButtonPress'))
        setIsPressed(true)
    }

    const handleButtonRelease = (e) => {
        // trace(run) && log(...msg('handleButtonRelease'))
        setIsPressed(false)
        click(e)
    }

    return (
        <button
            className={`button__neu ${isActive?.[nav] ? 'isActive' : ''} ${isPressed ? 'isPressed' : ''} ${additionalClass ? additionalClass : ''}`}
            data-nav={nav}
            onMouseDown={handleButtonPress}
            onMouseUp={handleButtonRelease}
        >
            {copy}
            {props.children}
        </button>
    )
}