import { useContext, useState, useEffect, useRef } from 'react'
import {
    /* Firebase */
    /* Components */
    SVGIcon,
    ProjectList,
    /* Context */
    MainContext,
    /* Views */
    /* Custom Hooks */
    /* Service Classes */
    DisplayService,
    /* Utility Functions */
    /* Assets */
    /* Icons */
    /* Configs */
    /* Types */
    /* Interfaces */
    /* DeveloperTools */
    debug,
    logInit,
    trace,
    m,
    About,
    Contact,
} from '../../app-index'

// import ProjectList from './project-list.component'

/* Trace vars */
const run = 0
const file = 'Accordion'
const msg = (copy, fileName = file) => m(copy, fileName)
/* END Trace vars */

export default function Accordion() {
    const {
        mainState: {
            sidebarActive,
            professionalActive,
            personalActive,
            activeEntryFlag,
            projectEntryIsActive,
            activeEntryHovered,
            isSubTablet,
            theme
        }
    } = useContext(MainContext)

    const [activeEntryIndex, setActiveEntryIndex] = useState(null)
    const [centeredProIndex, setCenteredProIndex] = useState(null)
    const [centeredPersIndex, setCenteredPersIndex] = useState(null)
    const proMainSwiperRef = useRef(null)
    const persMainSwiperRef = useRef(null)
    const sidebarRef = useRef(null)
    const [showPro, setShowPro] = useState(true)

    /* Handles delayed init/destroy on the non-current list of project entries. Allows time for transitions. */
    useEffect(() => {
        const displayCondition = professionalActive
            ? true
            : personalActive
                ? false
                : null

        let timeout
        timeout = setTimeout(() => setShowPro(displayCondition), 500) // Match CSS transition/animation durations
        return () => clearTimeout(timeout)
    }, [professionalActive, personalActive])

    useEffect(() => {
        // trace(run) && log(...msg('UE event blocker'))
        disableSlideWhenEntryActive()
        const sidebarOpenEntriesClosed = !projectEntryIsActive
        if (sidebarOpenEntriesClosed) {
            setTimeout(() => {
                if (professionalActive && proMainSwiperRef?.current?.destroyed !== true) {
                    proMainSwiperRef?.current?.mousewheel.enable()
                } else if (personalActive && persMainSwiperRef?.current?.destroyed !== true) {
                    persMainSwiperRef?.current?.mousewheel.enable()
                }
            }, 100)
        }
    }, [projectEntryIsActive])

    const disableSlideWhenEntryActive = () => {
        // trace(run) && log(...msg('disableSlideWhenEntryActive'))
        const swiperArray = [proMainSwiperRef.current, persMainSwiperRef.current]
        const eventArray = ['allowSlideNext', 'allowSlidePrev', 'allowTouchMove']
        swiperArray.forEach(swiper => {
            if (swiper && swiper.destroyed !== true) {
                eventArray.forEach(event => {
                    swiper[event] = !projectEntryIsActive
                })
            }
        })
    }

    useEffect(() => {
        // trace(run) && log(...msg('UE resize'))
        const entryActive = projectEntryIsActive
        if (entryActive) {
            if (professionalActive) {
                proMainSwiperRef.current?.mousewheel.disable()
            } else if (personalActive) {
                persMainSwiperRef.current?.mousewheel.disable()
            }
        }
    }, [projectEntryIsActive])

    useEffect(() => {
        // trace(run) && log(...msg('UE activeEntryIndex'), activeEntryIndex)
        DisplayService.setEntryIsActive(activeEntryIndex !== null)
    }, [activeEntryIndex])

    useEffect(() => {
        // trace(run) && log(...msg('UE activeEntryFlag Switch'))
        setActiveEntryIndex(null)
    }, [activeEntryFlag])

    useEffect(() => {
        // trace(run) && log(...msg('UE pro/pers active switch'))
        setActiveEntryIndex(null)
    }, [professionalActive, personalActive])

    const handleTitleClick = ({ target }) => {
        // trace(run) && log(...msg('handleTitleClick'))
        if (target.tagName.match(/H3|SPAN/)) {
            // Set clicked element as activeEntry, or toggle off activeEntry is user clicks the currently activeEntry
            setActiveEntryIndex(activeEntryIndex === +target.dataset.entryindex ? null : +target.dataset.entryindex)
        }
    }

    const handleChevronClick = (e) => {
        // trace(run) && log(...msg('handleChevronClick'), e.target, activeEntryIndex)
        // e.stopPropagation()
        // e.preventDefault()
        if (professionalActive) {
            setActiveEntryIndex(activeEntryIndex === centeredProIndex ? null : centeredProIndex)
        } else if (personalActive) {
            setActiveEntryIndex(activeEntryIndex === centeredPersIndex ? null : centeredPersIndex)
        }
    }

    const handleIndexChange = (swiper) => {
        // trace(run) && log(...msg('handleIndexChange'))
        if (swiper.hostEl.className.includes('pro')) {
            setCenteredProIndex(swiper.activeIndex)
        } else if (swiper.hostEl.className.includes('pers')) {
            setCenteredPersIndex(swiper.activeIndex)
        }
    }

    const handleSwiperInit = (swiper) => {
        // trace(run) && log(...msg('handleSwiperInit'))
        swiper.hostEl.className.includes('pro') ? (proMainSwiperRef.current = swiper) : (persMainSwiperRef.current = swiper)
        setCenteredPersIndex(swiper.activeIndex)
        setCenteredProIndex(swiper.activeIndex)
    }

    // TODO: Initial solution for solving the console warning about non-passive event listener within Swiper
    // Need to handle up/down wheel, and which swiper instance is currently displayed
    // Potential for limiting overscroll momentum scrolling on trackpads
    // useEffect(() => {
    //     const handleWheelEvent = () => {
    //         proMainSwiperRef.current.slideNext(300)
    //     }

    //     window.addEventListener('wheel', handleWheelEvent, { passive: true })
    //     return () => {
    //         window.removeEventListener('wheel', handleWheelEvent, { passive: true })
    //     }
    // }, [])

    // const handleEmailCopy = () => {
    //     const textarea = hiddenTextRef.current
    //     textarea.focus()
    //     textarea.select()

    //     try {
    //         const successful = document.execCommand('copy')
    //         console.log(successful ? 'Text copied!' : 'Failed to copy text.')
    //     } catch (err) {
    //         console.error('Failed to copy text: ', err)
    //     }

    //     setDisplayEmailAlert(true)
    //     setTimeout(() => {
    //         setDisplayEmailAlert(false)
    //     }, 2500)
    // }

    return (
        <div
            ref={sidebarRef}
            className={`sidebar ${sidebarActive ? 'isOpen' : ''}`}
        >
            <section className={`accordion-container ${professionalActive || personalActive ? 'isActive' : 'notActive'}`}>
                <div className={`display-pane ${projectEntryIsActive ? 'scrollDisabled' : ''}`} >
                    <div className={`focus--center ${sidebarActive ? 'isBlurred' : ''} ${activeEntryIndex !== null ? 'isExpanded' : ''} ${activeEntryHovered ? 'isHovered' : ''} ${isSubTablet ? 'isOnlyFocusAreaMobile' : ''}`} >
                        <div
                            className={`chevron-container ${activeEntryIndex !== null ? 'isOpen' : ''}`}
                            onClick={handleChevronClick}
                        >
                            <SVGIcon
                                src='circlePlus'
                                fill={theme === 'day' ? '#0f6ab1ee' : '#448ec8'}
                            />
                        </div>
                    </div>

                    {
                        showPro === true && (
                            <div className={`project-list ${professionalActive ? 'active' : 'hidden'}`}>
                                <ProjectList
                                    type="pro"
                                    handleIndexChange={handleIndexChange}
                                    handleSwiperInit={handleSwiperInit}
                                    handleTitleClick={handleTitleClick}
                                    activeEntryIndex={activeEntryIndex}
                                    handleChevronClick={handleChevronClick}
                                    centeredIndex={{ pro: centeredProIndex, pers: centeredPersIndex }}
                                />
                            </div>
                        )
                    }

                    {
                        showPro === false && (
                            <div className={`project-list ${!professionalActive ? 'active' : 'hidden'}`}>
                                <ProjectList
                                    type="pers"
                                    handleIndexChange={handleIndexChange}
                                    handleSwiperInit={handleSwiperInit}
                                    handleTitleClick={handleTitleClick}
                                    activeEntryIndex={activeEntryIndex}
                                    handleChevronClick={handleChevronClick}
                                    centeredIndex={{ pro: centeredProIndex, pers: centeredPersIndex }}
                                />
                            </div>
                        )
                    }
                </div>
            </section>
        </div>
    )
}
