import {
    /* Firebase */
    /* Components */
    /* Context */
    /* Views */
    /* Custom Hooks */
    /* Service Classes */
    /* Utility Functions */
    /* Assets */
    /* Icons */
    /* Configs */
    /* Types */
    /* Interfaces */
    /* DeveloperTools */
    debug,
    logInit,
    trace,
    m
} from '../../app-index'

/* Trace vars */
const run = 0
const file = 'DisplayService'
const msg = (copy, fileName = file) => m(copy, fileName)
/* END Trace vars */

class displayService {
    mainDispatch = null

    constructor() {
        /* Log class initialization */
        logInit && log(...msg('Init'))
        this.contentWrapperRef = null
        this.expandedContentRef = null
        this.entryContainerRef = null
    }

    setLocalDispatch(dispatch) {
        this.mainDispatch = dispatch
    }

    setEntryIsActive(isActive) {
        // trace(run) && log(...msg('setEntryIsActive'), isActive)

        // Pagination element is created and destroyed internally by the Swiper library. No access to a ref or element in the markup. Needs to use direct DOM manipulation
        const listPaginationClass = document.querySelector('.swiper-pagination-vertical').classList
        isActive ? listPaginationClass.add('swiper-pagination-hidden') : listPaginationClass.remove('swiper-pagination-hidden')

        const payload = {
            projectEntryIsActive: isActive
        }
        this.mainDispatch({ payload })
    }

    toggleFlagForActiveEntry(input) {
        const payload = {
            activeEntryFlag: !input
        }
        this.mainDispatch({ payload })
    }

    setSidebarState(input) {
        // trace(run) && log(...msg('setSidebarState'))

        const payload = {
            sidebarActive: input.professional || input.personal,
            professionalActive: input.professional,
            personalActive: input.personal,
            aboutActive: input.about,
            contactActive: input.contact,
            homeActive: input.home
        }

        this.mainDispatch({ payload })
    }

    setHexAnimation(animName) {
        const payload = {
            hexAnimationFloat: animName === 'float',
            hexAnimationFall: animName === 'fall',
            hexAnimationNone: animName === 'none',
        }
        this.mainDispatch({ payload })
    }

    setIsSubTablet(input) {
        const payload = {
            isSubTablet: input
        }
        this.mainDispatch({ payload })
    }

    closeBetaModal() {
        const payload = {
            isBetaActive: false
        }
        this.mainDispatch({ payload })
    }

    adjustExpandedContent(entryRef, contentRef, expandedRef, activeEntryIndex) { // TODO: Dynamically adjust expanded description as well
        // trace(run) && log(...msg('adjustExpandedContent'))
        
        const entryElem = entryRef.current
        if (entryElem.dataset.entryindex === activeEntryIndex?.toString() || activeEntryIndex === null) {
            const entryContainerTopPadding = 20
            const entryContainerBottomPadding = 20
            const entryContainerExpandedHeight = 600
            const contentArea = entryContainerExpandedHeight - entryContainerBottomPadding - entryContainerTopPadding

            const contentHeaderHeight = contentRef.current.children[0].getBoundingClientRect().height
            const contentHeaderBottomMargin = 10
            const innerContentVerticalDistanceFromParent = contentHeaderHeight + contentHeaderBottomMargin
            const footerAdjustment = 25 // Adjustment based on footer height and absolutely positioned child element 'hr'

            const expandedContentDynamicHeight = contentArea - innerContentVerticalDistanceFromParent - footerAdjustment
            expandedRef.current.attributes[1].nodeValue = `position: absolute; height: ${expandedContentDynamicHeight}px!important;`
        }
    }
    
    handleFocusHoverOn() {
        const payload = {
            activeEntryHovered: true
        }
        this.mainDispatch({ payload })
    }

    handleFocusHoverOff() {
        const payload = {
            activeEntryHovered: false
        }
        this.mainDispatch({ payload })
    }

}

export default displayService
