import { useRef, useState, useEffect } from 'react'
import {
    /* Firebase */
    /* Components */
    /* Context */
    /* Views */
    /* Custom Hooks */
    /* Service Classes */
    /* Utility Functions */
    /* Assets */
    /* Icons */
    iconPaths,
    /* Configs */
    /* Types */
    /* Interfaces */
    /* DeveloperTools */
    debug,
    logInit,
    trace,
    m,
} from '../../app-index'

/* Trace vars */
const run = 0
const file = 'SVGIcon'
const msg = (copy, fileName = file) => m(copy, fileName)
/* END Trace vars */

export default function SVGIcon({ src, fill, isHex, offsetX, offsetY, scale, additionalClass = '' }) {
    const pathRef = useRef(null)
    const [transform, setTransform] = useState('')

    useEffect(() => {
        // trace(run) && log(...msg('UE'))

        if (pathRef.current) {
            const boundingBox = pathRef.current.getBBox()
            const svgWidth = 512
            const svgHeight = 512
            const xCenter = (svgWidth - boundingBox.width) / 2 - boundingBox.x
            const yCenter = (svgHeight - boundingBox.height) / 2 - boundingBox.y
            const scaleFactor = scale ? scale : 1
            setTransform(`translate(${xCenter}, ${yCenter}) scale(${scaleFactor})`)
        }
    }, [])

    return (
        <div
            className={`svg-icon-container ${additionalClass}`}
            style={{ left: offsetX, top: offsetY }}
        >
            <svg
                className='standard-svg-icon'
                xmlns="http://www.w3.org/2000/svg"
                viewBox={`0 0 512 512`}
            >
                <path
                    transform={transform}
                    ref={pathRef}
                    fill={fill}
                    d={iconPaths[src]}
                />
            </svg>
        </div>
    )
}