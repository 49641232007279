import { useContext } from 'react'
import {
    /* Firebase */
    /* Components */
    About,
    Contact,
    /* Context */
    MainContext,
    /* Views */
    /* Custom Hooks */
    /* Service Classes */
    /* Utility Functions */
    /* Assets */
    /* Icons */
    /* Configs */
    /* Types */
    /* Interfaces */
    /* DeveloperTools */
    debug,
    logInit,
    trace,
    m
} from '../../app-index'

/* Trace vars */
const run = 0
const file = 'Name'
const msg = (copy, fileName = file) => m(copy, fileName)
/* END Trace vars */

export default function Name() {
    const {
        mainState: {
            projectEntryIsActive,
            contactActive,
            aboutActive
        }
    } = useContext(MainContext)

    return (
        <div className={`name-container ${projectEntryIsActive ? 'isHidden' : ''}`}>
            <span>Ash Thomas</span>
            <span>Fullstack Software Engineer</span>
            <hr />
            <section className={`specialties-section ${contactActive || aboutActive ? 'isHidden' : ''}`}>
                <span style={{ fontSize: 15 }}>Specialist in:</span>
                <span style={{ fontSize: 15, marginLeft: 5 }}>React & Angular</span>
                <span style={{ fontSize: 15, marginLeft: 5 }}>Data Visualization</span>
                <span style={{ fontSize: 15, marginLeft: 5 }}>Geographic Information Systems</span>
            </section>
            <About />
            <Contact />
        </div>
    )
}