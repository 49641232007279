import { useContext } from 'react'
import {
    /* Firebase */
    /* Components */
    /* Context */
    MainContext,
    /* Views */
    /* Custom Hooks */
    /* Service Classes */
    /* Utility Functions */
    /* Assets */
    /* Icons */
    /* Configs */
    /* Types */
    /* Interfaces */
    /* DeveloperTools */
    debug,
    logInit,
    trace,
    m
} from '../../app-index'

/* Trace vars */
const run = 0
const file = 'About'
const msg = (copy, fileName = file) => m(copy, fileName)
/* END Trace vars */

export default function About() {
    const {
        mainState: {
            aboutActive
        }
    } = useContext(MainContext)

    return (
        <section className={`about-container about-fade ${aboutActive ? 'isActive' : ''}`}>
            <p>
                My work as a software engineer is driven by a desire to solve real world problems, one line of code at at time.
                Outside of work I'm a mountain biker, environmental advocate, and classical pianist.
            </p>
        </section>
    )
}