/*** Trace vars ***/
const run = 0
const file = 'app-index'
DebugService.debug && DebugService.trace(run) && console.log(...DebugService.m('Init', file))
/*** END Trace vars ***/

/* DeveloperTools ***/
import DebugService from './app/_services/debug.service'
const trace = DebugService.trace
const m = DebugService.m
const debug = DebugService.debug
const logInit = DebugService.logInit
DebugService.assignGlobals()
DebugService.logEvents && DebugService.logUserEvents()
debug && timer('App load to Auth')
debug && timer('App load to ContentView')

export {
    trace,
    m,
    debug,
    logInit,
    DebugService
}


/*** Components ***/
import App from './app/global/App'
import AppView from './app/_views/app.view'
import ThemeToggle from './app/_components/theme-toggle.component'
import SVGIcon from './app/_components/svg-icon.component'
import SVGHex from './app/_components/svg-hex.component.js'
import NeuButton from './app/_components/neu-button.component.js'
import PrimaryNav from './app/_components/primary-nav.component.js'
import Name from './app/_components/name.component.js'
import Accordion from './app/_components/accordion.component.js'
import Beta from './app/_components/beta.component.js'
import About from './app/_components/about.component.js'
import Contact from './app/_components/contact.component.js'
import ProjectList from './app/_components/project-list.component.js'

export {
    App,
    AppView,
    ThemeToggle,
    SVGIcon,
    SVGHex,
    NeuButton,
    PrimaryNav,
    Name,
    Accordion,
    Beta,
    About,
    Contact,
    ProjectList
}


/*** Views ***/
import HeaderView from './app/_views/header.view'
import ContentView from './app/_views/content.view'

export {
    HeaderView,
    ContentView
}


/*** Custom Hooks ***/
import { useInitialRender as logComponentInit } from './app/hooks/initial-render.hook'

export {
    logComponentInit
}


/*** Service Classes ***/
import dataService from './app/_services/data.service'
import displayService from './app/_services/display.service'
import themeService from './app/_services/theme.service'
const ThemeService = new themeService()
const DataService = new dataService()
const DisplayService = new displayService()

export {
    DataService,
    DisplayService,
    ThemeService
}


/*** Utility Functions ***/
import {
    getStrTag,
    isTypeEquivalent,
    isArray,
    isObjLit,
    getLength,
    checkLength,
    lengthEquivalent,
    genNewId,
    genNewAlphaNumId,
    getParsedTypeFromStrTag
} from './app/_utilities/global.utilities'

export {
    getStrTag,
    isTypeEquivalent,
    isArray,
    isObjLit,
    getLength,
    checkLength,
    lengthEquivalent,
    genNewId,
    genNewAlphaNumId,
    getParsedTypeFromStrTag
}


/*** Assets ***/
import { galleryConfig, supplementaryImages } from './app/config/gallery-config.js'

export {
    galleryConfig,
    supplementaryImages,
}


/*** Icons ***/
import { iconSVGPathDStrings as iconPaths } from './_assets/icons/icon-paths.js'
export {
    iconPaths
}


/*** Configs ***/
import DataPaths from './app/config/data-paths'
import { errorConfigs } from './app/config/error-configs'

export {
    DataPaths,
    errorConfigs,
}


/*** Types ***/
import {
    FirebaseCreateOptions,
    FirebaseReadOptions,
    FirebaseUpdateOptions,
    FirebaseDeleteOptions
} from './app/types/firebase-types'
import { User } from './app/types/data-types'

export {
    FirebaseCreateOptions,
    FirebaseReadOptions,
    FirebaseUpdateOptions,
    FirebaseDeleteOptions,
    User
}


/*** Interfaces ***/
import AuthInterface from './app/interfaces/auth.interface'
import CRUDInterface from './app/interfaces/crud-interface'

export {
    AuthInterface,
    CRUDInterface
}


/*** Context ***/
import ContextValidator from './app/__context/ContextValidator'
import MainProvider from './app/__context/MainContext'
import {
    MainContext,
    initialMainState,
    initialMainStateTypes
} from './app/__context/MainContext'

export {
    MainProvider,
    MainContext,
    initialMainState,
    ContextValidator
}
