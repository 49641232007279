import { useRef, useState, useContext } from 'react'
import { MainContext } from '../../app-index'
import {
    /* Firebase */
    /* Components */
    /* Context */
    /* Views */
    /* Custom Hooks */
    /* Service Classes */
    /* Utility Functions */
    /* Assets */
    /* Icons */
    /* Configs */
    /* Types */
    /* Interfaces */
    /* DeveloperTools */
    debug,
    logInit,
    trace,
    m,
    MainContext,
} from '../../app-index'

/* Trace vars */
const run = 0
const file = 'SVGHex'
const msg = (copy, fileName = file) => m(copy, fileName)
/* END Trace vars */

export default function SVGHex({ src1, src2, fill, isHex, offsetX, offsetY, background }) {

    const {
        mainState: {
            hexAnimationFloat,
            hexAnimationFall,
            hexAnimationNone,
            theme,
            isSubTablet
        }
    } = useContext(MainContext)

    const containerRef = useRef(null)
    const [pathFill, setPathFill] = useState(fill)
    const [backgroundFill, setBackgroundFill] = useState(background)

    const highlight = (e) => {
        if (hexAnimationNone) return
        if (!hexAnimationFall) {
            setPathFill('#147ed2')
        }
        if (hexAnimationFall) {
            setPathFill('#147ed220')
            setBackgroundFill('#9aafd320')
        }
        const animationStyle =
            hexAnimationNone
                ? 'animate-none'
                : hexAnimationFall
                    ? 'animate-fall'
                    : 'animate-float'

        containerRef.current.classList.add(animationStyle)
    }

    const clear = () => {
        if (hexAnimationNone) return
        setPathFill(fill)
        if (hexAnimationFall) {
            setBackgroundFill(`#9aafd300`)
        }
        const animationStyle =
            hexAnimationNone
                ? 'animate-none'
                : hexAnimationFall
                    ? 'animate-fall'
                    : 'animate-float'

        containerRef.current.classList.remove(animationStyle)

        setTimeout(() => {
            setBackgroundFill(`#9aafd3aa`)
        }, 7000)
    }

    return (
        <div
            className='svg-hex-container hexagon'
            style={{ left: offsetX, top: offsetY }}
            ref={containerRef}
            onAnimationEnd={clear}
            onClick={isSubTablet ? highlight : null}
        >
            <svg
                className='standard-svg-icon'
                xmlns="http://www.w3.org/2000/svg"
                viewBox={`0 0 24.00 24.00`}
            >
                <g onMouseOver={highlight}>
                    <path
                        style={{ pointerEvents: 'all' }}
                        transform={'translate(4.4399999999999995,4.4399999999999995), scale(0.95)'}
                        fill={backgroundFill}
                        d="M8 0L15 4V12L8 16L1 12V4L8 0Z"
                        stroke={pathFill}
                        strokeWidth={2}
                    />
                </g>
            </svg>
        </div>

    )
}