import { useState, useEffect, useContext, useMemo, useRef } from 'react'

import {
    /* Firebase */
    /* Components */
    SVGIcon,
    /* Context */
    MainContext,
    /* Views */
    /* Custom Hooks */
    /* Service Classes */
    /* Utility Functions */
    /* Assets */
    supplementaryImages,
    /* Icons */
    /* Configs */
    /* Types */
    /* Interfaces */
    /* DeveloperTools */
    debug,
    logInit,
    trace,
    m
} from '../../app-index'

/* Trace vars */
const run = 0
const file = 'ProjectGallery'
const msg = (copy, fileName = file) => m(copy, fileName)
/* END Trace vars */

import { Swiper, SwiperSlide } from '../../../node_modules/swiper/swiper-react.mjs'
import { Pagination } from 'swiper/modules'

export default function ProjectGallery({ entry, handleIndexChange, isActive }) {
    const {
        mainState: {
            professionalActive,
            isSubTablet,
            theme
        }
    } = useContext(MainContext)

    const [contentArray, setContentArray] = useState([...entry.initialImage])
    const [supplementalArray, setSupplementalArray] = useState([])
    // const [activeIndex, setActiveIndex] = useState(0)

    const swiperRef = useRef(null)

    useEffect(() => {
        // trace(run) && log(...msg('UE'))
        setTimeout(() => {
            if (isActive) {
                const imageArray = supplementaryImages[professionalActive ? 'professional' : 'personal'].find(project => project.title === entry.title)
                setSupplementalArray([...imageArray.images])
            } else {
                setSupplementalArray([])
            }
        }, 900)
        // if (!isActive) {
        //     isSubTablet && setContentArray([])
        //     isSubTablet && setSupplementalArray([])
        // }
    }, [isActive])

    const prevSlide = () => {
        // trace(run) && log(...msg('prev'), swiperRef)
        swiperRef.current.swiper.slidePrev()
    }

    const nextSlide = () => {
        // trace(run) && log(...msg('next'), swiperRef)
        swiperRef.current.swiper.slideNext()
    }

    // const setActiveGallery = () => {
    //     trace(run) && log(...msg('setActiveGallery'))
    //     setActiveIndex(swiperRef.current.swiper.activeIndex)
    // }

    return (
        <div className='gallery-fade'>
            <Swiper
                direction={'horizontal'}
                // onActiveIndexChange={setActiveGallery}
                slidesPerView={1}
                spaceBetween={125}
                mousewheel={false}
                centeredSlides={true}
                slideToClickedSlide={true}
                pagination={{
                    clickable: false,
                }}
                modules={[Pagination]}
                className={`myGallery`}
                ref={swiperRef}
            >
                {
                    useMemo(() => {
                        return (
                            contentArray.map(image => {
                                return (
                                    <SwiperSlide key={Math.ceil(Math.random() * 10e15)} >
                                        <div className='image-frame'>
                                            <div className='image-wrapper' style={{ background: `url(${image})` }}></div>
                                        </div>
                                    </SwiperSlide>
                                )
                            })
                        )
                    }, [])
                }
                {
                    useMemo(() => {
                        return (
                            supplementalArray.map(image => {
                                return (
                                    <SwiperSlide key={Math.ceil(Math.random() * 10e15)} >
                                        <div className='image-frame'>
                                            <div className='image-wrapper' style={{ background: `url(${image})` }}></div>
                                        </div>
                                    </SwiperSlide>
                                )
                            })
                        )
                    }, [supplementalArray])
                }
                <div className="swiper-button-prev" onMouseUp={prevSlide}>
                    <SVGIcon
                        src='chevronLeft'
                        fill={theme === 'day' ? '#0f6ab1' : '#448ec8'}
                    />
                </div>
                <div className="swiper-button-next" onMouseUp={nextSlide}>
                    <SVGIcon
                        src='chevronRight'
                        fill={theme === 'day' ? '#0f6ab1' : '#448ec8'}
                    />
                </div>
            </Swiper>
        </div>
    )
}