import { useContext, useEffect, useMemo, useRef } from 'react'
import {
    /* Firebase */
    /* Components */
    SVGIcon,
    /* Context */
    MainContext,
    /* Views */
    /* Custom Hooks */
    /* Service Classes */
    /* Utility Functions */
    /* Assets */
    /* Icons */
    /* Configs */
    /* Types */
    /* Interfaces */
    /* DeveloperTools */
    debug,
    logInit,
    trace,
    m,
    DisplayService
} from '../../app-index'

/* Trace vars */
const run = false
const file = 'NEW'
const msg = (copy, fileName = file) => m(copy, fileName)
/* END Trace vars */
import ProjectGallery from './project-gallery.component'

export default function ProjectEntry({ entry, index, entryClass, activeEntryIndex, handleIndexChange, centeredIndex, handleChevronClick }) {
    const {
        mainState: {
            sidebarActive,
            isSubTablet,
            projectEntryIsActive,
            professionalActive,
            personalActive,
            activeEntryHovered
        }
    } = useContext(MainContext)

    const isActive = activeEntryIndex === index
    const isInactive = activeEntryIndex !== index
    const entryContainerRef = useRef(null)
    const contentWrapperRef = useRef(null)
    const expandedContentRef = useRef(null)

    useEffect(() => {
        if (!projectEntryIsActive) return
        getEntryDimensions()
    }, [projectEntryIsActive])

    const getEntryDimensions = () => {
        if (professionalActive && entry.type === 'pers') return
        if (personalActive && entry.type === 'pro') return
        DisplayService.adjustExpandedContent(
            entryContainerRef,
            contentWrapperRef,
            expandedContentRef,
            activeEntryIndex
        )
    }

    useEffect(() => {
        let resizeTimeout
        const getDelayedEntryDimensions = () => {
            clearTimeout(resizeTimeout)
            resizeTimeout = setTimeout(() => {
                getEntryDimensions()
            }, 650)
        }
        window.addEventListener('resize', getDelayedEntryDimensions)
        return () => {
            window.removeEventListener('resize', getDelayedEntryDimensions)
        }
    }, [isActive])

    const handleFocusHover = () => {
        DisplayService.handleFocusHoverOn()
    }

    const handleFocusClear = () => {
        DisplayService.handleFocusHoverOff()
    }

    const isCentered = professionalActive
        ? index === centeredIndex?.pro
        : index === centeredIndex?.pers

    return (
        <div
            className={`entry-container ${entryClass} ${isActive ? `isActive` : ``} ${activeEntryIndex !== null && !isActive && activeEntryIndex - 1 === index ? 'pushUp' : activeEntryIndex !== null && !isActive && activeEntryIndex + 1 === index ? 'pushDown' : ''} ${!isSubTablet ? 'isDesktopVersion' : ''}`}
            ref={entryContainerRef}
            data-entrytag={entry.tag}
            data-entryindex={index}
            onClick={isActive ? null : handleChevronClick}
            onMouseOver={isActive ? null : handleFocusHover}
            onMouseOut={isActive ? null : handleFocusClear}
        >
            <div
                className={`chevron-container within-entry ${activeEntryIndex !== null ? 'isOpen' : ''}`}
                onClick={handleChevronClick}
            >
                <SVGIcon
                    src='circlePlus'
                    fill={'#448ec8'}
                />
            </div>
            <div
                className={`content-wrapper ${sidebarActive ? 'isOpen' : ''} ${activeEntryHovered && isCentered ? 'isHovered' : ''}`}
                ref={contentWrapperRef}
                data-entrytag={entry.tag}
                data-entryindex={index}
                data-openentry={true}
            >
                <h3
                    data-entrytag={entry.tag}
                    data-entryindex={index}
                    data-openentry={true}
                >
                    {entry.title}
                </h3>
                <div className='inner-content-wrapper'>
                    <span
                        className={`inactive-description ${isInactive ? 'isDisplayed' : 'isHidden'}`}
                        data-entrytag={entry.tag}
                        data-entryindex={index}
                        data-openentry={true}
                    >
                        {entry.shortDescription}
                    </span>
                    <div
                        className='expanded-content'
                        style={{ position: 'absolute' }}
                        ref={expandedContentRef}
                    >
                        <div className='expanded-header'>

                            <div className='stack-list'>
                                {
                                    entry.stackList.map((item, index) => {
                                        return (<span key={Math.ceil(Math.random() * 10e15)}>
                                            <span>{item}</span>
                                            {index < entry.stackList.length - 1 ? `\u25CF` : null}
                                        </span>
                                        )
                                    })
                                }
                            </div>
                            <div className='role-link-section'>
                                <span className='role'>{entry.role}</span>
                                {
                                    entry.liveLink
                                        ? <span className='live-link'><a href={entry.liveLink} target='_blank'>Live Site</a></span>
                                        : null
                                }
                            </div>
                            <hr />
                        </div>
                        <div className='expanded-gallery-container'>
                            <ProjectGallery entry={entry} handleIndexChange={handleIndexChange} isActive={isActive} />
                            <div className='extended-description'>
                                <p>
                                    {entry.description}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <section className={`entry-footer ${isActive ? 'isActive' : ''}`}>
                <hr />
                {entry.status ? (<span className='status'><em>{entry.status}</em></span>) : null}
                {entry.gitLink ? (<span className='git-link'><a href={entry.gitLink} target='_blank'>View on Github</a></span>) : null}
            </section>
        </div>
    )
}