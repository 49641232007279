import { useContext, useMemo } from 'react'
import { Swiper, SwiperSlide } from '../../../node_modules/swiper/swiper-react.mjs'
import { Mousewheel, Pagination, FreeMode } from 'swiper/modules'

import {
    /* Firebase */
    /* Components */
    SVGIcon,
    /* Context */
    MainContext,
    /* Views */
    /* Custom Hooks */
    /* Service Classes */
    DisplayService,
    /* Utility Functions */
    /* Assets */
    galleryConfig,
    /* Icons */
    /* Configs */
    /* Types */
    /* Interfaces */
    /* DeveloperTools */
    debug,
    logInit,
    trace,
    m
} from '../../app-index'

/* Trace vars */
const run = 0
const file = 'ProjectList'
const msg = (copy, fileName = file) => m(copy, fileName)
/* END Trace vars */

// import { Swiper, SwiperSlide } from 'swiper/react'

import ProjectEntry from './project-entry.component'

export default function ProjectList({ type, handleIndexChange, handleSwiperInit, handleTitleClick, activeEntryIndex, handleChevronClick, centeredIndex }) {
    const {
        mainState: {
            professionalActive,
            personalActive,
            aboutActive,
            contactActive,
            isSubTablet,
        }
    } = useContext(MainContext)

    const fadeClass = type === 'pro' ? 'pro-fade' : 'pers-fade'
    const entryClass = type === 'pro' ? 'professional-entry' : 'personal-entry'
    const galleryCategory = type === 'pro' ? galleryConfig.professional : galleryConfig.personal
    const galleryActive = type === 'pro' ? professionalActive : personalActive
    const isDisabled = aboutActive || contactActive

    return (
        <div className={`${fadeClass} ${galleryActive ? 'isActive' : ''} ${isDisabled ? 'isDisabled' : ''}`}>
            <Swiper
                onSwiper={handleSwiperInit}
                onActiveIndexChange={handleIndexChange}
                direction={'vertical'}
                resistanceRatio={0.1}
                touchRatio={0.5}
                // freeMode={true}
                speed={500}
                // freeModeMomentum={true}
                slidesPerView={3}
                spaceBetween={isSubTablet ? 140 : 110}
                mousewheel={true}
                centeredSlides={true}
                slideToClickedSlide={false}
                pagination={{
                    // hideOnClick: true,
                    clickable: false,
                }}
                modules={[Mousewheel, Pagination]}
                className={`mySwiper ${type}`}
            >
                {
                    galleryCategory.map((entry, index) => {
                        return (
                            <SwiperSlide
                                key={entry.title}
                                onClick={handleTitleClick}
                            >
                                <ProjectEntry
                                    entry={entry}
                                    index={index}
                                    entryClass={entryClass}
                                    activeEntryIndex={activeEntryIndex}
                                    handleIndexChange={handleIndexChange}
                                    centeredIndex={centeredIndex}
                                    handleChevronClick={handleChevronClick}
                                />
                            </SwiperSlide>
                        )
                    })
                }
                {/* {
                    !isSubTablet
                        ? (
                            <div
                                className={`focus--center ghost-elem ${activeEntryIndex !== null ? 'isExpanded' : ''}`}
                                onMouseOver={handleFocusHover}
                                onMouseOut={handleFocusClear}
                                data-openentry={true}
                                onMouseUp={handleChevronClick}
                            >
                                {
                                    useMemo(() => {
                                        return (

                                            <div
                                                className={`chevron-container ${activeEntryIndex !== null ? 'isOpen' : ''}`}
                                                onMouseUp={handleChevronClick}
                                            >
                                                <SVGIcon
                                                    src='circlePlus'
                                                    fill={'#448ec8'}
                                                />
                                            </div>
                                        )
                                    }, [activeEntryIndex])
                                }
                            </div>
                        )
                        : null
                } */}
            </Swiper>
        </div>
    )
}